import { connect } from 'react-redux'
import Promo from '../components/Promo'
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return {
    client:state.service.client,
    event:state.service.event
  };
}


const mapDispatchToProps = (dispatch) => ({
  fetchEvent: (clientId, callback) => {
    dispatch(api.fetchEvent(clientId)).then(callback)
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(Promo)
