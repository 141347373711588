import { connect } from 'react-redux'
import * as api from '../store/api'
import CheckTickets from '../components/CheckTickets';

const mapStateToProps = (state) => {
    return {
        client: state.service.client,
        event: state.service.event
    };
}


const mapDispatchToProps = (dispatch) => ({
    getEventPurchases: (eventId, phone, callback) => {
        dispatch(api.getEventPurchases(eventId, phone)).then(callback)
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(CheckTickets)
