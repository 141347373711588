import _ from 'lodash';
import https from 'https'
import 'cross-fetch/polyfill';

const API_ENDPOINT = "ay4op9wl42.execute-api.ap-southeast-2.amazonaws.com/Prod/"
const API_PROTOCOL = "https://"
const API_REGION = "ap-southeast-2"

function parseResponse(res) {

    var promise = new Promise((resolve, reject) => {

        var parsedData = null
        var errorMsg = ""

        res.setEncoding('utf8');
        let rawData = '';
        res.on('data', (chunk) => { rawData += chunk; });
        res.on('end', () => {
            try {
                parsedData = JSON.parse(rawData);

            } catch (e) {
                errorMsg = e.message
            }
            if (parsedData) {
                resolve(parsedData)
            } else {
                resolve({
                    error: errorMsg
                })
            }
        });
    });

    return promise

}


function onTest(data) {

    return {
        type: 'TEST',
        data: data,
        receivedAt: Date.now()
    }
}

export function test(data) {
    return function (dispatch) {
        let val = data;
        dispatch(onTest(val))
    }
}

export function testAsync() {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            setTimeout(function () {
                let val = Math.random();
                dispatch(onTest(val))
                resolve();
            }, 1500)


        })
    }
}

function onFetchEvent(data) {

    return {
        type: 'FETCH_EVENT',
        data: data,
        receivedAt: Date.now()
    }
}

export function fetchEvent(clientId) {
    clientId = clientId.split("/")[1];

    return function (dispatch) {
        return new Promise(async (resolve, reject) => {

            // get client by stub
            const res = await fetch(API_PROTOCOL + API_ENDPOINT + "getlatestevent?stub=" + clientId)
            if (!res || !res.ok) {
                console.log("error with fetchEvent", res)
                dispatch(onFetchEvent(null))
                resolve(false);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data && data.hasEvents) {
                    dispatch(onFetchEvent(data))
                    resolve(data.hasEvents);
                } else {
                    dispatch(onFetchEvent(null))
                    resolve(false);
                }
            }


        })
    }
}

function onFetchPastEvents(data) {

    return {
        type: 'FETCH_PAST_EVENT',
        data: data,
        receivedAt: Date.now()
    }
}

export function fetchPastEvents(clientId) {
    return function (dispatch) {
        return new Promise(async (resolve, reject) => {

            // get client by stub
            const res = await fetch(API_PROTOCOL + API_ENDPOINT + "getclientevents?clientId=" + clientId)
            if (!res || !res.ok) {
                console.log("error with fetchPastEvent", res)
                dispatch(onFetchPastEvents(null))
                resolve([]);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    dispatch(onFetchPastEvents(data))
                    resolve(data);
                } else {
                    dispatch(onFetchPastEvents(null))
                    resolve([]);
                }
            }


        })
    }
}

export function purchase(data) {
    return function (dispatch) {
        return new Promise(async (resolve, reject) => {
            console.log("purchase data", data)
            // get client by stub
            const res = await fetch(API_PROTOCOL + API_ENDPOINT + "buytickets", {
                method:"POST",
                headers: { 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8' },
                body:JSON.stringify({
                    clientId: data.clientId,
                    eventId: data.eventId,
                    ticketCount: data.ticketCount,
                    phone: data.phone,
                    cardName: data.cardName,
                    cardNumber: data.cardNumber,
                    expiryMonth: data.expiryMonth,
                    expiryYear: data.expiryYear,
                    cvc: data.cvc,
                    email: data.email,
                    optIn: data.optIn
                })
            })
            if (!res || !res.ok) {
                console.log("error with purchase", res)
                resolve({ok:false, message:"There was an error processing your purchase. Please try again."});
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    resolve(data);
                } else {
                    resolve({ok:false, message:"There was an error processing your purchase. Please try again."});
                }
            }


        })
    }
}

function onGetEventPurchases(data) {

    return {
        type: 'GET_EVENT_PURCHASES',
        data: data,
        receivedAt: Date.now()
    }
}

export function getEventPurchases(eventId, phone) {
    return function (dispatch) {
        return new Promise(async (resolve, reject) => {

            // get client by stub
            const res = await fetch(API_PROTOCOL + API_ENDPOINT + "geteventpurchases?eventId=" + eventId + "&phone=" + phone)
            if (!res || !res.ok) {
                console.log("error with getEventPurchases", res)
                dispatch(onGetEventPurchases(null))
                resolve([]);
            } else {
                console.log("res", res)

                const data = await res.json()
                if (data) {
                    dispatch(onGetEventPurchases(data))
                    resolve(data);
                } else {
                    dispatch(onGetEventPurchases(null))
                    resolve([]);
                }
            }


        })
    }
}