import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'
import { Container, Row, Col, Button, ToggleButton, ToggleButtonGroup, Form, ProgressBar } from 'react-bootstrap'
import 'core-js';
import _ from 'lodash'
import moment from 'moment'

import Page from './Page'
import '../styles/global.css'
import styleHelpers from '../helpers/StyleHelpers'

const TIMER = 10

class BuyTickets extends Page {

    state = {
        hasEvent: false,
        fetchingEvent: true,
        purchasing: false,
        purchaseComplete: false,
        message: "",
        refreshingEvent: false,
        timer: 0,
        // purchase details
        phone: "",
        email: "",
        optIn: false,
        cardName: "",
        cardNumber: "",
        expiryYear: "-1",
        expiryMonth: "-1",
        cvc: "",
        ticketCount: 0
    }

    componentDidMount() {

        window.scrollTo(0, 0)

        this._refreshEvent = this._refreshEvent.bind(this)

        let self = this
        if (!self.props.client || !self.props.event) {
            self.setState({ fetchingEvent: true }, () => {
                self.props.fetchEvent(self.props.location.pathname, (hasEvent) => {
                    self._updateBundles(hasEvent)
                })
            })
        } else {
            self.setState({ hasEvent: true, fetchingEvent: false })
            self._updateBundles(true)
        }

        // 
    }

    _refreshEvent() {
        if (this.state.refreshingEvent) return
        this.setState({ timer: this.state.timer + 1 })
        if (this.state.timer >= TIMER) {

            let self = this
            self.setState({ refreshingEvent: true, timer: 0 }, () => {
                self.props.fetchEvent(self.props.location.pathname, (hasEvent) => {
                    self.setState({ hasEvent: hasEvent, refreshingEvent: false })
                })
            })
        }
    }

    _updateBundles(hasEvent) {
        let ticketCount = 9999
        let order = []
        for (var key in this.props.event.bundles) {
            order.push(Number(this.props.event.bundles[key]))
        }
        order.sort(function (a, b) { return a - b });
        ticketCount = order.length > 2 ? order[2] : (order.length > 1 ? order[1] : order[0])
        this.setState({ hasEvent: hasEvent, fetchingEvent: false, ticketCount: ticketCount })

        this._interval = setInterval(this._refreshEvent, 1000);
    }

    _costOfTickets() {
        for (var k in this.props.event.bundles) {
            if (this.state.ticketCount == this.props.event.bundles[k]) {
                return k
            }
        }
        return 0
    }

    _buyTickets() {
        let self = this
        let phone = this.state.phone.replace(" ", "")
        let email = this.state.email.replace(" ", "")
        let optIn = this.state.optIn
        let cardName = this.state.cardName
        let cardNumber = this.state.cardNumber.replace(" ", "")
        let expiryYear = this.state.expiryYear
        let expiryMonth = this.state.expiryMonth
        let cvc = this.state.cvc.replace(" ", "")
        let ticketCount = Number(this.state.ticketCount)


        let valid = true
        let errorMsg = ""
        if (!phone || phone == "") {
            valid = false
            errorMsg += (errorMsg == "" ? "" : "\n") + "Please enter a valid phone number"
        }

        if (!email || email == "") {
            valid = false
            errorMsg += (errorMsg == "" ? "" : "\n") + "Please enter a valid email address"
        }

        if (!cardName || cardName == "") {
            valid = false
            errorMsg += (errorMsg == "" ? "" : "\n") + "Please enter a valid credit card name"
        }

        if (!cardNumber || cardNumber == "" || cardNumber.length != 16) {
            valid = false
            errorMsg += (errorMsg == "" ? "" : "\n") + "Please enter a valid credit card number"
        }

        if (expiryYear == "-1") {
            valid = false
            errorMsg += (errorMsg == "" ? "" : "\n") + "Please select a credit card expiry year"
        }

        if (expiryMonth == "-1") {
            valid = false
            errorMsg += (errorMsg == "" ? "" : "\n") + "Please select a credit card expiry month"
        }

        if (!cvc || cvc == "" || cvc.length != 3) {
            valid = false
            errorMsg += (errorMsg == "" ? "" : "\n") + "Please enter a valid cvc. This is the 3 digit code on the back of your credit card."
        }

        if (!valid) {
            this.setState({
                message: errorMsg
            })
            return
        }

        // implement method to purchase
        this.setState({ purchasing: true }, () => {
            this.props.purchase({
                message: "",
                clientId: self.props.client.PK,
                eventId: self.props.event.SK,
                phone: phone,
                email: email,
                optIn: optIn,
                cardName: cardName,
                cardNumber: cardNumber,
                expiryYear: expiryYear,
                expiryMonth: expiryMonth,
                cvc: cvc,
                ticketCount: ticketCount
            }, (response) => {
                console.log("purchase response", response)
                self.setState({
                    purchasing: false,
                    purchaseComplete: response.success,
                    message: response.message
                })
            })
        })




    }

    render() {
        let self = this
        if (self.state.fetchingEvent && (!self.state.hasEvent || !this.props.client || !this.props.event)) {
            return (
                <Container>
                    <div className="text-center v-margin-20">
                        <img src="/images/loaders/oval.svg" />
                    </div>
                </Container>
            )
        } else if (!self.state.fetchingEvent && (!self.state.hasEvent || !this.props.client || !this.props.event)) {
            return (
                <Container>
                    <p className="text-center">EVENT NOT FOUND</p>
                </Container>
            )
        }

        styleHelpers.applyStyles(this.props.client.styles)
        let purchaseComplete = <div className="formGroup text-center"><img src={this.props.client.styles.success} alt="success" /><div className="mx-auto text-center mb-1 confirmation">
            <p>Thank you for your purchase and donation. A receipt has been sent to <span>{this.state.phone}</span></p>
        </div><Button onClick={() => self._nagivateToPage("/" + this.props.client.stub)} className="btn btn-secondary back mt-4 mb-5">&#8592; Back</Button></div>

        let bundleBtns = []
        for (var key in self.props.event.bundles) {
            bundleBtns.push(
                <ToggleButton
                    value={self.props.event.bundles[key]}
                    toggle={true}
                    className="buyBtn"
                    onChange={(e) => {
                        console.log(e.target.key)
                        self.setState({ ticketCount: e.target.value })
                    }}>
                    <b>{self.props.event.bundles[key]}</b> for <b>${key}</b>
                </ToggleButton>
            )
        }

        let message = []
        if (this.state.message != "") {
            message.push(<div>{this.state.message}</div>)
            message.push(<br />)
        }

        return (
            <div>
                <Row>
                    <Col md={12}>
                        <ProgressBar min={0} max={TIMER - 1} now={this.state.timer} />
                    </Col>
                </Row>
                <Container>

                    <div className="mx-auto content mt-5 no-padding-bottom">
                        <img src={this.props.client.styles.logo} className="logo" alt="logo" />

                        <div id="chevron">
                            Live Prize Pool
                    </div>
                        <div id="prizepool">
                            <span>${this.props.event.prizePool}</span>
                        </div>
                        <div id="event_no">
                            <span>EVENT #{this.props.event.SK}</span>
                        </div>
                    </div>
                    {this.state.purchaseComplete ? purchaseComplete :
                        <div className="mx-auto content mb-4 no-padding-top">

                            <Form>
                                <fieldset disabled={this.state.purchasing ? "disabled" : ""}>
                                    <Row>
                                        <Col md={12}>
                                            <ToggleButtonGroup type="radio" className="buyBtnGroup" name="options" defaultValue={this.state.ticketCount}>
                                                {bundleBtns}
                                            </ToggleButtonGroup>

                                            <Form>
                                                <div className="formGroup">
                                                    <h4>Personal Details</h4>

                                                    <Form.Group className="text-left">
                                                        <Form.Label>Mobile Number. This is where your tickets will be sent so please make sure it is correct.</Form.Label>
                                                        <Form.Control
                                                            type="phone"
                                                            placeholder="Enter phone"
                                                            onChange={(e) => {
                                                                self.setState({ phone: e.target.value.replace(/[^0-9+]/g,'') })
                                                            }}
                                                            value={self.state.phone} />
                                                    </Form.Group>

                                                    <Form.Group className="text-left">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Enter email"
                                                            onChange={(e) => {
                                                                self.setState({ email: e.target.value })
                                                            }}
                                                            value={self.state.email} />
                                                    </Form.Group>

                                                    <Form.Check
                                                        type='checkbox'
                                                        label={this.props.client.optInMessage}
                                                        onChange={(e) => {
                                                            self.setState({ optIn: e.target.checked })
                                                        }}
                                                        checked={self.state.optIn}
                                                    />
                                                </div>
                                                <div className="formGroup">
                                                    <h4>Payment Details</h4>

                                                    <Form.Group className="text-left">
                                                        <Form.Label>Name on Card</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter name"
                                                            onChange={(e) => {
                                                                self.setState({ cardName: e.target.value })
                                                            }}
                                                            value={self.state.cardName}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="text-left">
                                                        <Form.Label>Card Number</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Card Number"
                                                            onChange={(e) => {
                                                                self.setState({ cardNumber: e.target.value })
                                                            }}
                                                            value={self.state.cardNumber}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridState" className="text-left no-padding">
                                                        <Form.Label>Expiry Month</Form.Label>
                                                        <Form.Control as="select"
                                                            onChange={(e) => {
                                                                self.setState({ expiryMonth: e.target.value })
                                                            }}
                                                            value={self.state.expiryMonth}
                                                        >
                                                            <option value="-1">Choose...</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </Form.Control>
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridState" className="text-left no-padding">
                                                        <Form.Label>Expiry Year</Form.Label>
                                                        <Form.Control as="select"
                                                            className="custom-select mr-3"
                                                            onChange={(e) => {
                                                                self.setState({ expiryYear: e.target.value })
                                                            }}
                                                            value={self.state.expiryYear}
                                                        >
                                                            <option value="-1">Choose...</option>
                                                            <option value="2021">2021</option>
                                                            <option value="2022">2022</option>
                                                            <option value="2023">2023</option>
                                                            <option value="2024">2024</option>
                                                            <option value="2025">2025</option>
                                                            <option value="2026">2026</option>
                                                            <option value="2027">2027</option>
                                                            <option value="2028">2028</option>
                                                            <option value="2029">2029</option>
                                                            <option value="2030">2030</option>
                                                        </Form.Control>
                                                    </Form.Group>

                                                    <Form.Group className="text-left">
                                                        <Form.Label>CVC</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="CVC"
                                                            onChange={(e) => {
                                                                self.setState({ cvc: e.target.value })
                                                            }}
                                                            value={self.state.cvc} />
                                                    </Form.Group>
                                                    By purchasing tickets you have read and agreed to the <a href={this.props.client.terms}>Terms and conditions</a>
                                                    <h4 className="txt-gld formGroup errorMsg">{message}</h4>
                                                </div>
                                            </Form>
                                            {this.state.purchasing ? <div>
                                                <div className="text-center v-margin-20">
                                                    <img src="/images/loaders/oval.svg" />
                                                </div><p>Purchase in progress...</p></div> :
                                                <div>
                                                    <Button onClick={() => this._buyTickets()} className="btn btn-primary btn-block mt-4">Buy {this.state.ticketCount} Tickets for ${this._costOfTickets()}</Button>
                                                    <Button onClick={() => self._nagivateToPage("/" + this.props.client.stub)} className="btn btn-secondary back mt-4 mb-5">&#8592; Back</Button>
                                                </div>}
                                        </Col>
                                    </Row>


                                </fieldset>
                            </Form>
                        </div>}
                </Container>
            </div>
        )
    }
}

export default withRouter(BuyTickets)