import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap'
import 'core-js';
import _ from 'lodash'
import moment from 'moment'

import Page from './Page'
import '../styles/global.css'
import '../helpers/NumberHelpers'
import numberHelpers from '../helpers/NumberHelpers'



class CheckTickets extends Page {

    state = {
        phone: "",
        tickets: null,
        fetchingTickets: false
    }

    componentDidMount() {

    }

    _checkTickets() {
        let self = this

        self.setState({
            phone: self.state.phone.replace(/ /g, "")
        }, () => {

            if (self.state.phone.length < 10) {
                alert("Please enter a valid mobile phone number.")
            } else {
                self.setState({ fetchingTickets: true }, () => {
                    self.props.getEventPurchases(self.props.eventId, self.state.phone, (tickets) => {
                        self.setState({ tickets: tickets }, () => {
                            self.setState({ fetchingTickets: false })
                        })
                    })
                })
            }
        })

    }

    render() {
        let self = this

        let tickets = null
        if (this.state.fetchingTickets) {
            tickets = <div>Checking tickets...</div>
        } else if (this.state.tickets) {
            if (this.state.tickets.length == 0) {
                tickets = <div>No tickets were purchased in #{this.props.eventId} with the phone number {this.state.phone}</div>
            } else {
                let ticketRows = []
                for (var i = 0; i < this.state.tickets.length; i++) {
                    for (var j = 0; j < this.state.tickets[i].ticketCount; j++) {
                        ticketRows.push(
                            <li>{this.state.tickets[i].PK}_{numberHelpers.pad(this.state.tickets[i].ticketIndex + j, 3)}{self.props.winningTicketId == this.state.tickets[i].ticketIndex + j ? <b> - WINNER</b> : ""}</li>
                        )
                    }

                }
                tickets = <ul>{ticketRows}</ul>
            }
        }

        return (
            <Modal {...this.props} onEnter={
                () => {
                    this.setState({ tickets: null, fetchingTickets: false })
                }
            }>
                <Modal.Header closeButton>
                    <Modal.Title>Check your ticket for event #{this.props.eventId}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    Enter the mobile phone number that your tickets were sent to:<br />
                    <Form>
                        <Form.Group>
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Mobile number"
                                onChange={(e) => {
                                    self.setState({ phone: e.target.value, tickets: null })
                                }}
                                value={self.state.phone} />
                        </Form.Group>
                        <Button className="btn btn-tertiary" onClick={() => this._checkTickets()}>Check Tickets</Button>
                    </Form>

                    {tickets}

                </Modal.Body>
            </Modal>
        )
    }
}

export default withRouter(CheckTickets)