import { connect } from 'react-redux'
import PastEvents from '../components/PastEvents'
import * as api from '../store/api'

const mapStateToProps = (state) => {
    return {
        client: state.service.client,
        event: state.service.event
    };
}


const mapDispatchToProps = (dispatch) => ({
    fetchEvent: (clientId, callback) => {
        dispatch(api.fetchEvent(clientId)).then(callback)
    },
    fetchPastEvents: (clientId, callback) => {
        dispatch(api.fetchPastEvents(clientId)).then(callback)
    }
})


export default connect(mapStateToProps, mapDispatchToProps)(PastEvents)
