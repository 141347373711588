import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { loadingBarMiddleware } from 'react-redux-loading-bar'

import rootReducer from './store/reducers';
import 'bootstrap/dist/css/bootstrap.css'


import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom'

import Dashboard from './containers/DashboardContainer';
import BuyTickets from './containers/BuyTicketsContainer';
import PastEvents from './containers/PastEventsContainer';
import Promo from './containers/PromoContainer';


const loggerMiddleware = createLogger()

const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        loadingBarMiddleware())
)


ReactDOM.render(

    <Provider store={store}>

        <Router>
            <Switch>
                <Route exact path="/*/buy" component={BuyTickets} />
                <Route exact path="/*/events" component={PastEvents} />
                <Route exact path="/*/promos/*" component={Promo} />
                <Route exact path="/*" component={Dashboard} />
            </Switch>
        </Router>
    </Provider>
    ,

    document.getElementById('root')
);
