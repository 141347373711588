import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'
import { Container, Row, Col, Button, ProgressBar } from 'react-bootstrap'
import 'core-js';
import _ from 'lodash'
import moment from 'moment'
import numberHelpers from '../helpers/NumberHelpers'
import styleHelpers from '../helpers/StyleHelpers'

import Page from './Page'
import '../styles/global.css'
import CheckTickets from '../containers/CheckTicketsContainer'

const TIMER = 10

class Dashboard extends Page {

    state = {
        fetchingEvent: false,
        hasEvent: false,
        showCheckTickets: false,
        refreshingEvent: false,
        timer: 0
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        let self = this
        this._closeCheckTickets = this._closeCheckTickets.bind(this)
        this._refreshEvent = this._refreshEvent.bind(this)

        if (!self.props.client || !self.props.event) {
            self.setState({ fetchingEvent: true }, () => {
                self.props.fetchEvent(self.props.location.pathname, (hasEvent) => {
                    self._eventReady(hasEvent)
                })
            })
        } else {
            self.setState({ timer: 10 }, () => {
                self._eventReady(true)
            })

        }
    }

    componentWillUnmount() {
        if (this._interval) clearInterval(this._interval);
    }

    _eventReady(hasEvent) {
        let self = this
        self.setState({ hasEvent: hasEvent, fetchingEvent: false })
        this._interval = setInterval(this._refreshEvent, 1000);

    }

    _refreshEvent() {
        if (this.state.refreshingEvent || this.state.fetchingEvent) return
        this.setState({ timer: this.state.timer + 1 })
        if (this.state.timer >= TIMER) {

            let self = this
            self.setState({ refreshingEvent: true, timer: 0 }, () => {
                self.props.fetchEvent(self.props.location.pathname, (hasEvent) => {
                    self.setState({ hasEvent: hasEvent, refreshingEvent: false })
                })
            })
        }
    }

    _closeCheckTickets() {
        this.setState({ showCheckTickets: false })
    }



    render() {
        let self = this

        if (self.props.location.pathname == "/") {
            return (<Container>
                <p className="text-center">EVENT NOT FOUND</p>
            </Container>)
        }

        if (!self.state.hasEvent || !this.props.client || !this.props.event) {
            return (
                <Container>
                    <div className="text-center v-margin-20">
                        <img src="/images/loaders/oval.svg" />
                    </div>
                </Container>
            )
        }

        if (self.state.hasEvent && (!this.props.client || !this.props.event)) {
            return (
                <Container>
                    <p className="txt-gld text-center">EVENT NOT FOUND</p>
                </Container>
            )
        }

        styleHelpers.applyStyles(this.props.client.styles)

        let content = null
        if (self.props.event.completed) {
            content =
                <div>
                    <div className="backgroundImage">
                        <div className="hero"><img src={this.props.client.styles.hero} alt="hero" /></div>
                        <Container className="contentContainer">

                            <CheckTickets
                                show={this.state.showCheckTickets}
                                onHide={this._closeCheckTickets}
                                eventId={this.props.event.SK} />


                            <div className="mx-auto content">
                                <img src={this.props.client.styles.logo} className="logo" alt="logo" />
                                <div id="chevron">
                                    Final Prize Pool
                        </div>
                                <div id="prizepool">
                                    <span>${this.props.event.prizePool.toFixed(2)}</span>
                                </div>
                                <div id="event_no">
                                    <span>EVENT #{this.props.event.SK}</span>
                                </div>
                                <h4 className="txt-red">This event has now completed and was drawn on {moment(this.props.event.endDate).format("DD/MM/YY")} at {moment(this.props.event.endDate).format("h:mma")}</h4>
                                {this.props.event.winningTicket != "-" ?
                                    <p>The winning ticket was {this.props.event.SK}_{numberHelpers.pad(this.props.event.winningTicket, 3)}</p>
                                    : null}
                                <Button onClick={() => self.setState({ showCheckTickets: true })} className="btn btn-secondary mt-4">Check my Ticket</Button>
                                <Button onClick={() => self._nagivateToPage("/" + this.props.client.stub + "/events")} className="btn btn-secondary mt-3">Past Events</Button>

                            </div>

                        </Container>

                    </div>
                </div>
        } else {
            content = <div>

                <div className="backgroundImage">
                    <div className="hero"><img src={this.props.client.styles.hero} alt="hero" /></div>
                    <Row>
                        <Col md={12}>
                            <ProgressBar min={0} max={TIMER - 1} now={this.state.timer} />
                        </Col>
                    </Row>

                    <Container className="contentContainer">

                        <CheckTickets
                            show={this.state.showCheckTickets}
                            onHide={this._closeCheckTickets}
                            eventId={this.props.event.SK} />


                        <div className="mx-auto content">
                            <img src={this.props.client.styles.logo} className="logo" alt="logo" />
                            <div id="chevron">
                                Live Prize Pool
                        </div>
                            <div id="prizepool">
                                <span>${this.props.event.prizePool.toFixed(2)}</span>
                            </div>
                            <div id="event_no">
                                <span>EVENT #{this.props.event.SK}</span>
                            </div>
                            <h4 className="txt-red">Winner drawn when prize pool reaches ${this.props.event.maxPrizePool}{this.props.event.drawDate && this.props.event.drawDate != "" ? " OR ON " + moment(new Date(this.props.event.drawDate)).format("DD/MM/YY") + " AFTER " + moment(new Date(this.props.event.drawDate)).format("h:mma") : ""}</h4>
                            {this.props.client.altPromo ? <p>{this.props.client.altPromo}</p> : <p>Buy a ticket to support {this.props.client.charity} and for your chance to win 50% of the final prize pool.</p>}
                            <div className="box">
                                <h4>winner</h4>
                                <span>${(Number(this.props.event.prizePool) / 2).toFixed(2)}</span>
                            </div>
                            <div className="box transparent mb-5">
                                <h4>{this.props.client.charityAlt ? this.props.client.charityAlt : this.props.client.charity}</h4>
                                <span>${(Number(this.props.event.prizePool) / 2).toFixed(2)}</span>
                            </div>
                            <Button onClick={() => self._nagivateToPage("/" + this.props.client.stub + "/buy")} className="btn btn-primary btn-block mb-4">Buy Tickets</Button>
                            <Button onClick={() => self.setState({ showCheckTickets: true })} className="btn btn-secondary mt-4">Check my Ticket</Button>
                            <Button onClick={() => self._nagivateToPage("/" + this.props.client.stub + "/events")} className="btn btn-secondary mt-3">Past Events</Button>

                        </div>

                    </Container>

                </div>

            </div>
        }

        return (
            content
        )
    }
}

export default withRouter(Dashboard)        