import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import 'core-js';
import _ from 'lodash'
import moment from 'moment'
import numberHelpers from '../helpers/NumberHelpers'

import Page from './Page'
import '../styles/global.css'
import CheckTickets from '../containers/CheckTicketsContainer'
import styleHelpers from '../helpers/StyleHelpers'


class PastEvents extends Page {

    state = {
        events: null,
        showCheckTickets: false,
        winningTicketId: null,
        eventId: null
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        let self = this

        this._closeCheckTickets = this._closeCheckTickets.bind(this)

        if (!self.props.client) {
            self.setState({ fetchingEvent: true }, () => {
                self.props.fetchEvent(self.props.location.pathname, (hasEvent) => {
                    self._fetchPastEvents()
                })
            })
        } else {
            self._fetchPastEvents()
        }


    }

    _closeCheckTickets() {
        this.setState({ showCheckTickets: false })
    }

    _fetchPastEvents() {
        this.props.fetchPastEvents(this.props.client.PK, (events) => {
            this.setState({ events: events })
        })
    }

    render() {
        let self = this

        if (!this.state.events) {
            return (
                <Container>
                    <div className="text-center v-margin-20">
                        <img src="/images/loaders/oval.svg" />
                    </div>
                </Container>
            )
        }

        styleHelpers.applyStyles(this.props.client.styles)

        let events = []
        if (this.state.events.length > 0) {

            let eventRows = []

            for (let i = 0; i < this.state.events.length; i++) {
                eventRows.push(
                    <tr>
                        <td scope="row">{this.state.events[i].SK}</td>
                        <td>{this.state.events[i].completed ? moment(this.state.events[i].endDate).format("DD/MM/YY") + " at " + moment(this.state.events[i].endDate).format("h:mma") : "LIVE"}</td>
                        <td>${this.state.events[i].prizePool}</td>
                        <td>{this.state.events[i].completed ? this.state.events[i].winningTicket == "-" ? "-" : this.state.events[i].SK + "_" + numberHelpers.pad(this.state.events[i].winningTicket, 3) : "-"}</td>
                        <td><Button onClick={() => {
                            self.setState({ eventId: self.state.events[i].SK, winningTicketId: self.state.events[i].winningTicket }, () => {
                                self.setState({ showCheckTickets: true })
                            })
                        }} className="btn btn-secondary">Check my ticket</Button></td>
                    </tr>
                )
            }

            events = <div className="table-responsive-md">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Event</th>
                            <th scope="col">Draw Date</th>
                            <th scope="col">Prize Pool</th>
                            <th scope="col">Winning Ticket</th>
                            <th scope="col">Check Ticket</th>
                        </tr>
                    </thead>
                    <tbody>
                        {eventRows}
                    </tbody>
                </table>
            </div>
        } else {
            events = <div>No previous events found</div>
        }

        return (
            <Container>

                <CheckTickets
                    show={this.state.showCheckTickets}
                    onHide={this._closeCheckTickets}
                    eventId={this.state.eventId}
                    winningTicketId={this.state.winningTicketId} />

                <div className="mx-auto content mt-5 mb-4">
                    <img src={this.props.client.styles.logo} className="logo" alt="logo" />
                    <h2 className="txt-red mt-5">Past Events</h2>
                </div>
                {events}
                <Button onClick={() => self._nagivateToPage("/" + this.props.client.stub)} className="btn btn-secondary back mt-4 mb-5">&#8592; Back</Button>

            </Container>
        )
    }
}

export default withRouter(PastEvents)