
const initialState = {
    testValue: 0,
    client: null,
    event: null
}

const service = (state = initialState, action) => {
    switch (action.type) {
        case "TEST":
            return { ...state, testValue: action.data }
        case "FETCH_EVENT":
            let client = action.data ? action.data.client : null
            let event = action.data ? action.data.event : null
            return { ...state, client: client, event: event }
        default:
            return state;
    }
}

export default service
