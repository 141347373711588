import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom'
import { Container, Row, Col, Button, ProgressBar } from 'react-bootstrap'
import 'core-js';
import _ from 'lodash'
import moment from 'moment'
import numberHelpers from '../helpers/NumberHelpers'
import styleHelpers from '../helpers/StyleHelpers'
import QRCode from "qrcode.react";

import Page from './Page'
import '../styles/global.css'

const TIMER = 10

class Promo extends Page {

    state = {
        fetchingEvent: false,
        hasEvent: false,
        showCheckTickets: false,
        refreshingEvent: false,
        timer: 0
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        let self = this
        this._refreshEvent = this._refreshEvent.bind(this)

        if (!self.props.client || !self.props.event) {
            self.setState({ fetchingEvent: true }, () => {
                self.props.fetchEvent(self.props.location.pathname, (hasEvent) => {
                    self._eventReady(hasEvent)
                })
            })
        } else {
            self.setState({ timer: 10 }, () => {
                self._eventReady(true)
            })

        }
    }

    componentWillUnmount() {
        if (this._interval) clearInterval(this._interval);
    }

    _eventReady(hasEvent) {
        let self = this
        self.setState({ hasEvent: hasEvent, fetchingEvent: false })
        this._interval = setInterval(this._refreshEvent, 1000);

    }

    _refreshEvent() {
        if (this.state.refreshingEvent || this.state.fetchingEvent) return
        this.setState({ timer: this.state.timer + 1 })
        if (this.state.timer >= TIMER) {

            let self = this
            self.setState({ refreshingEvent: true, timer: 0 }, () => {
                self.props.fetchEvent(self.props.location.pathname, (hasEvent) => {
                    console.log("refreshed!")
                    self.setState({ hasEvent: hasEvent, refreshingEvent: false })
                })
            })
        }
    }

    render() {
        let self = this

        if (self.props.location.pathname == "/") {
            return (<Container>
                <p className="text-center">EVENT NOT FOUND</p>
            </Container>)
        }

        if (!self.state.hasEvent || !this.props.client || !this.props.event) {
            return (
                <Container>
                    <p className="text-center">LOADING...</p>
                </Container>
            )
        }

        if (self.state.hasEvent && (!this.props.client || !this.props.event)) {
            return (
                <Container>
                    <p className="txt-gld text-center">EVENT NOT FOUND</p>
                </Container>
            )
        }

        styleHelpers.applyStyles(this.props.client.styles)
        let pathArr = self.props.location.pathname.split("/")
        let promoType = pathArr[pathArr.length - 1].toLowerCase()
        let adStyles = "backgroundImage " + promoType

        let squareContent =

            <div className={adStyles} style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>
                <Container className="contentContainer">

                    <div className="mx-auto content">
                        <img src={this.props.client.styles.logo} className="logo" alt="logo" />
                        <div className="v-margin-20">
                            <div id="event_no">
                                <span>EVENT #{this.props.event.SK}</span>
                            </div>
                        </div>
                        <div id="chevron">
                            Live Prize Pool
                        </div>
                        <div id="prizepool" >
                            <span>${this.props.event.prizePool.toFixed(2)}</span>
                        </div>
                        <div className="v-margin-20">
                            <h4>{this.props.client.promoMessage}</h4>
                        </div>

                    </div>

                </Container>

            </div>

        let mrecContent =

            <div className={adStyles} style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>
                <Container className="contentContainer">

                    <div className="mx-auto content" style={{ paddingTop: 10 }}>
                        <img src={this.props.client.styles.logo} className="logo" alt="logo" />
                        <div className="v-margin-20">
                            <div id="event_no">
                                <span>EVENT #{this.props.event.SK}</span>
                            </div>
                        </div>
                        <div id="chevron">
                            Live Prize Pool
                        </div>
                        <div id="prizepool" >
                            <span>${this.props.event.prizePool.toFixed(2)}</span>
                        </div>
                        <div className="v-margin-20">
                            <h4>{this.props.client.promoMessage}</h4>
                        </div>

                    </div>

                </Container>

            </div>

        let rectContent =

            <div className={adStyles} style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>
                <div className="hero"><img src={this.props.client.styles.hero} alt="hero" /></div>
                <Container className="contentContainer">

                    <div className="mx-auto content" style={{ maxWidth: "100%", paddingTop: 10 }}>
                        <Row>
                            <Col xs={8}>
                                <div className="v-margin-20">
                                    <div id="event_no">
                                        <span>EVENT #{this.props.event.SK}</span>
                                    </div>
                                </div>
                                <div id="chevron">
                                    Live Prize Pool
                                </div>
                                <div id="prizepool" style={{ maxWidth: "70%" }}>
                                    <span>${this.props.event.prizePool.toFixed(2)}</span>
                                </div>
                                <div className="v-margin-10">
                                    <h4>{this.props.client.promoMessage}</h4>
                                </div>
                            </Col>
                            <Col xs={4}>

                            </Col>
                        </Row>



                    </div>

                </Container>

            </div>

        let scoreboardContent =

            <div className={adStyles} style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>

                <Container className="contentContainer">

                    <div className="mx-auto content" style={{ maxWidth: "100%", paddingTop: 0 }}>
                        <Row>
                            <Col xs={6}>
                                <div className="v-margin-20">
                                    <div id="event_no">
                                        <span>EVENT #{this.props.event.SK}</span>
                                    </div>
                                </div>
                                <div id="chevron">
                                    Live Prize Pool
                                </div>
                                <div id="prizepool" style={{ maxWidth: "90%" }}>
                                    <span style={{fontSize:90}}>${this.props.event.prizePool.toFixed(2)}</span>
                                </div>
                                <div className="v-margin-10">
                                    <h4 style={{fontSize:72}}>{this.props.client.promoMessage}</h4>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div style={{ marginBottom: 20, marginTop: 20 }}>
                                    <QRCode
                                        size={400}
                                        value={"https://s50.events/" + self.props.client.stub}
                                        level="H"
                                        imageSettings={{
                                            src: "/images/qr-logo.png",
                                            width: 120,
                                            height: 120
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>



                    </div>

                </Container>


            </div>

        let cubeContent =

            <div className={adStyles} style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>
                <div className="hero"><img src={this.props.client.styles.hero} alt="hero" /></div>
                <Container className="contentContainer">

                    <div className="mx-auto content" style={{ maxWidth: "100%", paddingTop: 10 }}>

                        {/* <img src={this.props.client.styles.logo} alt="logo" style={{ width: "18%" }} /> */}
                        <div className="v-margin-20">
                            <div id="event_no" style={{ paddingBottom: 20 }}>
                                <span style={{ fontSize: 24 }}>EVENT #{this.props.event.SK}</span>
                                <h4 style={{ fontSize: 48 }}>{this.props.client.promoMessage}</h4>
                            </div>
                        </div>

                        <div id="chevron">
                            Live Prize Pool
                        </div>
                        <div id="prizepool" style={{ maxWidth: "70%", paddingTop: 10 }}>
                            <span style={{ fontSize: 80 }}>${this.props.event.prizePool.toFixed(2)}</span>
                        </div>

                        <div style={{ marginBottom: 20, marginTop: 20 }}>
                            <QRCode
                                size={600}
                                value={"https://s50.events/" + self.props.client.stub}
                                level="H"
                                imageSettings={{
                                    src: "/images/qr-logo.png",
                                    width: 120,
                                    height: 120
                                }}
                            />
                        </div>

                    </div>

                </Container>

            </div>

        let clickContent =

            <div className={adStyles} style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>
                <Container className="contentContainer">

                    <div className="mx-auto content" style={{ paddingTop: 10 }}>
                        <img src={this.props.client.styles.logo} className="logo" alt="logo" />
                        <div className="v-margin-20">
                            <div id="event_no">
                                <span>EVENT #{this.props.event.SK}</span>
                            </div>
                        </div>

                        <div id="chevron">
                            Live Prize Pool
                        </div>
                        <div id="prizepool" >
                            <span>${this.props.event.prizePool.toFixed(2)}</span>
                        </div>
                        <div className="v-margin-20">
                            <h4>Click here to purchase tickets</h4>
                            <h5>S50.EVENTS/{this.props.client.stub}</h5>
                        </div>

                    </div>

                </Container>

            </div>


        let content = squareContent
        switch (promoType) {
            case "square":
                content = squareContent
                break
            case "mrec":
                content = mrecContent
                break
            case "rect":
                content = rectContent
                break
            case "scoreboard":
                content = scoreboardContent
                break
            case "cube":
                content = cubeContent
                break
            case "click":
                content = clickContent
                break
        }

        return (
            content
        )
    }
}

export default withRouter(Promo)
